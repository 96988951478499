<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    width="80%"
    :fullscreen="fullscreen"
    style="z-index: 1000"
    v-model="showDialog"
    @input="close"
  >
    <v-card class="ma-0 pa-0 ma-md-5 pa-md-5">
      <v-card-title class="d-flex mb-5">
        <div v-if="hasTitleSlot" class="headerClass">
            <slot name="title" v-if="hasTitleSlot">
                {{ title }}
            </slot>
        </div>
        <v-spacer />
        <div>
          <v-btn @click="showDialog = null" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
      </v-card-title>

      <v-card-subtitle v-if="subtitle || hasSubtitleSlot">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </v-card-subtitle>

      <v-card-text class="mx-0 py-5 px-0 ma-md-5 pa-md-5">
        <slot>
          <v-sheet class="pa-5"> No result found </v-sheet>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
      value(val){
          this.showDialog = val;
      }
  },
  data: () => ({
    showDialog: false,
  }),
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title;
    },
    hasSubtitleSlot() {
      return !!this.$slots.subtitle;
    },
  },
  mounted() {
      this.showDialog = this.value;
  },
  methods: {
      close(){
          this.showDialog = null;
          this.$emit("input", this.showDialog)
      }
  }
};
</script>

<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}
</style>
